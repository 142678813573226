/**=====================
  3.46 Add-product CSS Start
==========================**/
.sidebar-left-wrapper {
    border-right: 1px solid var(--recent-dashed-border);
    @media (max-width:1199px) {
        border-right: unset;
        margin-bottom: 25px;
    }
    [dir="rtl"] & {
        border-right: unset;
        border-left: 1px solid var(--recent-dashed-border);
    }
    .sidebar-left-icons {
        display: block;
        .nav-item {
            position: relative;
            padding-bottom: 50px;
            @media (max-width:1199px) {
                padding-bottom: 25px;
            }
            &:first-child {
                .nav-link {
                    padding-top: 0;
                }
            }
            &:last-child {
                padding-bottom: 0;
                .nav-link {
                    padding-bottom: 0;
                }
            }
            &::before {
                content: "";
                position: absolute;
                border: 1px dashed var(--recent-dashed-border);
                top: 60px;
                height: 50px;
                left: 20px;
                [dir="rtl"] & {
                    left: unset;
                    right: 20px;
                }
                @media (max-width:1199px) {
                    height: 25px;
                }
            }
            &:first-child {
                &::before {
                    top: 50px;
                }
            }
            &:last-child {
                &::before {
                    content: none;
                }
                .nav-link {
                    padding-bottom: 0;
                }
            }
            .nav-link {
                display: flex;
                align-items: center;
                gap: 12px;
                padding: 8px 0;
                &.active,
                &:active {
                    background-color: unset;
                    color: unset;
                    .nav-rounded {
                        border: 1px solid var(--theme-default);
                        .product-icons {
                            background-color: var(--theme-default);
                            svg {
                                stroke: var(--white);
                            }
                        }
                    }
                    .product-tab-content {
                        h6 {
                            color: var(--theme-default);
                            font-weight: 500;
                        }
                    }
                }
                .nav-rounded {
                    min-width: 42px;
                    height: 42px;
                    border: 1px solid var(--recent-dashed-border);
                    position: relative;
                    z-index: 2;
                    border-radius: 100%;
                    padding: 2px;
                    box-shadow: inset 0px 9px 20px 0px rgb(46 35 94 / 7%);
                    z-index: 1;
                    .product-icons {
                        min-width: 100%;
                        height: 100%;
                        background-color: var(--white);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 100%;
                        svg {
                            width: 18px;
                            height: 18px;
                            stroke: var(--chart-text-color);
                        }
                    }
                }
                .product-tab-content {
                    h6 {
                        color: var(--body-font-color);
                        font-weight: 400;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        width: 100%;
                        @media (max-width:575px) {
                            text-align: left;
                        }
                    }
                    p {
                        color: var(--chart-text-color);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        width: 100%;
                        @media (max-width:575px) {
                            text-align: left;
                        }
                    }
                }
            }
            &:hover {
                .nav-rounded {
                    border: 1px solid var(--theme-default);
                    .product-icons {
                        background-color: rgba(var(--light-shade-primary), 0.11);
                        svg {
                            stroke: var(--theme-default);
                            animation: tada 1.5s ease infinite;
                        }
                    }
                }
                .product-tab-content {
                    h6 {
                        color: var(--theme-default);
                    }
                }
            }
        }
    }
}
// Product 
.sidebar-body {
    form {
        .form-control,
        .form-select {
            &:focus {
                box-shadow: unset;
                border-color: unset;
            }
        }
    }
    .ql-snow {
        .ql-picker-label {
            [dir="rtl"] & {
                padding-right: 8px;
                padding-left: 2px;
            }
        }
    }
    .ql-toolbar {
        &.ql-snow {
            .ql-formats {
                @media (max-width:575px) {
                    margin-right: 0 !important;
                }
            }
        }
    }
    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        [dir="rtl"] & {
            right: unset;
            left: 0;
        }
    }
    .form-select {
        [dir="rtl"] & {
            padding: 6px 12px 6px 36px !important;
        }
    }
    .ql-formats {
        border-right: 1px solid var(--recent-dashed-border);
        [dir="rtl"] & {
            border-left: 1px solid var(--recent-dashed-border);
            border-right: unset;
        }
        @media (max-width:575px) {
            border-right: unset;
            [dir="rtl"] & {
                border-left: unset;
            }
        }
        &:last-of-type {
            border-right: unset;
            [dir="rtl"] & {
                border-left: unset;
            }
        }
    }
    #editor2 {
        min-height: 250px;
    }
}
// Gallery product
.sidebar-body {
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: inner-spin-button;
    }
    .form-select,
    .form-control {
        font-size: 15px;
        color: $color-scarpa;
    }
    .product-upload {
        p {
            margin-bottom: 18px;
            line-height: 0;
        }
        &:nth-child(1) {
            margin-bottom: 20px;
            @media (max-width:400px) {
                margin-bottom: 26px;
            }
        }
        &:nth-child(2) {
            margin-bottom: 65px;
            @media (max-width:1199px) {
                margin-bottom: 0;
            }
        }
        form {
            svg {
                width: 48px;
                height: 48px;
            }
            h6 {
                font-weight: 400;
                font-size: 14px;
                line-height: 1.8;
                cursor: pointer !important;
                a {
                    cursor: pointer !important;
                }
            }
            span {
                color: var(--chart-text-color);
                font-size: 14px;
            }
        }
    }
}
.product-buttons {
    margin: 15px 0 0 0;
    text-align: end;
    border-top: 1px solid var(--recent-dashed-border);
    padding: 12px 0 0 0;
    position: absolute;
    bottom: 0;
    width: calc(100% - 48px);
    left: 50%;
    transform: translateX(-50%);
    [dir="rtl"] & {
        left: unset;
        right: 50%;
        transform: translateX(50%);
    }
    @media (max-width:1199px) {
        position: unset;
        width: unset;
        transform: unset;
        [dir="rtl"] & {
            transform: unset;
        }
    }
    svg {
        width: 18px;
        height: 18px;
        stroke: var(--chart-text-color);
        @media (max-width:400px) {
            width: 15px;
            height: 15px;
        }
    }
    .btn {
        border: 1px solid var(--recent-dashed-border);
        color: var(--chart-text-color);
        font-weight: 500;
        @media (max-width:1400px) {
            padding: 6px 16px;
        }
        @media (max-width:400px) {
            padding: 6px 8px;
        }
        &:hover,
        &:active {
            background-color: var(--theme-default);
            color: $light;
            border-color: var(--theme-default);
            svg {
                stroke: $light;
            }
        }
    }
}
.category-button {
    padding-bottom: 20px;
    border-bottom: 1px dashed var(--recent-dashed-border);
    @media (max-width:575px) {
        padding-bottom: 25px;
    }
    a {
        padding: 12px;
    }
}
.create-category {
    .toolbar-box {
        margin-top: 12px;
    }
}
.product-date {
    .form-control[readonly] {
        background-color: var(--white);
    }
}
.price-wrapper {
    .radio-wrapper {
        justify-content: flex-start;
        margin-top: 4px;
        li {
            max-width: 200px;
            .form-check-input {
                border-radius: 5px;
                background-color: var(--light-background);
                ~.form-check-label {
                    span {
                        color: $color-scarpa;
                        font-size: 15px;
                        position: relative;
                    }
                }
                &:checked {
                    background-color: var(--light-background);
                    border: 1px solid var(--theme-default);
                    border-radius: 5px;
                    ~.form-check-label {
                        span {
                            color: var(--theme-default);
                            position: relative;
                        }
                    }
                }
            }
        }
    }
}
// Advance options
.advance-options {
    ul {
        border-bottom: 1px solid var(--recent-dashed-border);
        &.border-tab {
            &.nav-tabs {
                .nav-item {
                    .nav-link {
                        color: var(--body-font-color);
                        border-bottom: 2px solid transparent;
                        transition: all 0.3S ease-in-out;
                        &:active,
                        &.active {
                            border-bottom: 2px solid var(--theme-default);
                            color: var(--body-font-color);
                            transition: all 0.3S ease-in-out;
                        }
                    }
                }
            }
        }
    }
}
.meta-body {
    padding-top: 16px;
    .custom-input {
        .form-check {
            .form-check-input {
                &:checked {
                    background-color: var(--theme-default);
                }
                &:valid {
                    border-color: var(--theme-default);
                    ~ {
                        .form-check-label {
                            color: rgba($theme-body-sub-title-color, 0.8);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
.product-tag {
    .tagify {
        border-radius: 6px;
        padding: 0;
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
    }
}
.tagify__tag {
    margin: 5px 0 5px 5px;
    [dir="rtl"] & {
        margin-right: 5px;
        margin-left: 0px;
    }
}
.tagify__input {
    margin: 0;
}
.tagify__tag__removeBtn {
    [dir="rtl"] & {
        margin-right: auto;
        margin-left: 4.6666666667px;
    }
}
// list-product scss
.list-product-header {
    >div {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 12px;
        margin-bottom: 14px;
    }
    .show {
        display: block;
    }
    .hide {
        display: none;
    }
    .collapse {
        line-height: 0.2;
    }
    .light-box {
        width: 39px;
        height: 39px;
        border-radius: 5px;
        background-color: var(--light-shade-primary);
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
        @media (max-width:350px) {
            width: 35px;
            height: 35px;
        }
        svg {
            width: 17px;
            height: 17px;
            color: var(--chart-text-color);
        }
    }
    a {
        &.btn {
            float: right;
            [dir="rtl"] & {
                float: left;
            }
            i {
                padding-right: 8px;
                [dir="rtl"] & {
                    padding-right: unset;
                    padding-left: 8px;
                }
            }
            @media (max-width:575px) {
                float: unset;
            }
        }
    }
    .list-product-body {
        margin-top: 20px;
        padding: 0px;
        box-shadow: unset;
        .form-select {
            font-size: 14px;
            border-color: var(--recent-dashed-border);
            color: var(--chart-text-color);
        }
    }
}
.list-product {
    &.list-category {
        tr {
            th,
            td {
                &:nth-child(2) {
                    min-width: 60px;
                }
            }
            th,
            td {
                &:nth-child(3) {
                    min-width: 300px;
                }
            }
            th,
            td {
                &:nth-child(4) {
                    min-width: 150px;
                }
            }
            th,
            td {
                &:nth-child(5) {
                    min-width: 100px;
                }
            }
        }
        .datatable-bottom {
            padding-bottom: 0;
        }
    }
    thead {
        tr {
            th {
                &:first-child {
                    .form-check {
                        display: none;
                    }
                }
            }
        }
    }
    .datatable-container {
        overflow-x: auto;
        thead {
            tr {
                th {
                    &:nth-child(8) {
                        @media (max-width:1445px) {
                            display: none;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                cursor: pointer;
                td {
                    &:nth-child(8) {
                        @media (max-width:1445px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .datatable-dropdown {
        position: relative;
        label {
            color: var(--body-font-color);
            &:before {
                content: "\f107";
                position: absolute;
                top: 6px;
                left: 38px;
                font-family: "FontAwesome";
                font-size: 16px;
                margin: 0;
                color: var(--chart-text-color);
                [dir="rtl"] & {
                    right: 38px;
                    left: unset;
                }
            }
        }
    }
    .datatable-selector {
        appearance: none;
        min-width: 55px;
        &:focus,
        &:focus-visible {
            outline: none;
        }
    }
    .datatable-sorter {
        &::before,
        &::after {
            [dir="rtl"] & {
                right: unset;
                left: 4px;
            }
        }
    }
    tr {
        th,
        td {
            text-align: left;
            [dir="rtl"] & {
                text-align: right;
            }
        }
        th:nth-child(1) {
            .datatable-sorter {
                &::before,
                &::after {
                    border: none;
                }
            }
        }
        th,
        td {
            &:nth-child(1) {
                min-width: 20px;
            }
        }
        th,
        td {
            &:nth-child(2) {
                min-width: 110px;
                @media (max-width:1460px) {
                    min-width: 230px;
                }
            }
        }
        th,
        td {
            &:nth-child(3) {
                min-width: 90px;
            }
        }
        th,
        td {
            &:nth-child(4) {
                min-width: 120px;
            }
        }
        th,
        td {
            &:nth-child(5) {
                min-width: 120px;
            }
        }
        th,
        td {
            &:nth-child(6) {
                min-width: 80px;
            }
        }
        th,
        td {
            &:nth-child(7) {
                min-width: 100px;
            }
        }
        th,
        td {
            &:nth-child(8) {
                min-width: 140px;
            }
        }
        th,
        td {
            &:nth-child(9) {
                min-width: 105px;
            }
        }
    }
    &:focus-visible {
        outline: 0;
    }
    .datatable-top {
        display: flex;
        gap: 22px;
        flex-wrap: wrap;
        margin-bottom: 12px;
        @media (max-width:523px) {
            gap: 10px;
        }
    }
    .datatable-search {
        float: left;
        [dir="rtl"] & {
            float: right;
        }
    }
    .datatable-container {
        &:focus-visible {
            outline: 0;
        }
    }
    .datatable-bottom {
        .datatable-info {
            color: var(--body-font-color);
        }
    }
    .datatable-top {
        padding: 0;
    }
    .datatable-pagination {
        li {
            &.datatable-hidden {
                visibility: visible;
                a {
                    color: var(--theme-default);
                    font-weight: 500;
                    opacity: 1;
                }
            }
        }
        .datatable-active {
            .datatable-pagination-list-item-link {
                background-color: var(--theme-default);
                color: var(--white);
            }
        }
        .datatable-pagination-list-item-link {
            background-color: rgba(var(--light-shade-primary), 0.02);
            border-radius: 5px;
        }
    }
}
.table {
    &#project-status {
        tbody {
            tr {
                td {
                    vertical-align: inherit;
                }
            }
        }
    }
}
.product-names {
    display: flex;
    align-items: center;
    gap: 15px;
    .light-product-box {
        min-width: 49px;
        height: 49px;
        border-radius: 3px;
        background-color: var(--chart-progress-light);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 26px;
        }
    }
}
.product-action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    svg {
        width: 18px;
        height: 18px;
        cursor: pointer;
        fill: var(--chart-text-color);
    }
}
.datatable-input,
.datatable-selector {
    border: 1px solid var(--recent-dashed-border);
    border-radius: 5px;
    background: unset;
}
.product-list {
    .rdt_Table {
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;
        .rdt_TableHead {
            .rdt_TableHeadRow {
                .rdt_TableCol {
                    background: var(--light2);
                    padding: 13px 10px;
                    .rdt_TableCol_Sortable {
                        div {
                            color: #52526C;
                            font-weight: 600;
                            opacity: 0.8;
                            font-size: 14px;
                        }
                    }
                    &:nth-child(1) {
                        min-width: 20px;
                    }
                    &:nth-child(2) {
                        min-width: 320px;
                        @media (max-width: 1460px) {
                            min-width: 330px;
                        }
                    }
                    &:nth-child(3) {
                        min-width: 122px;
                    }
                    &:nth-child(4) {
                        min-width: 165px;
                    }
                    &:nth-child(5) {
                        min-width: 120px;
                    }
                    &:nth-child(6) {
                        min-width: 80px;
                    }
                    &:nth-child(7) {
                        min-width: 100px;
                    }
                    &:nth-child(8) {
                        min-width: 140px;
                        @media (max-width: 1445px) {
                            display: none;
                        }
                    }
                    &:nth-child(9) {
                        min-width: 105px;
                    }
                }
            }
        }
        .rdt_TableBody {
            .rdt_TableRow {
                .rdt_TableCell {
                    padding: 18px 10px;
                    &:nth-child(1) {
                        min-width: 20px;
                    }
                    &:nth-child(2) {
                        min-width: 320px;
                        @media (max-width: 1460px) {
                            min-width: 330px;
                        }
                    }
                    &:nth-child(3) {
                        min-width: 122px;
                    }
                    &:nth-child(4) {
                        min-width: 165px;
                    }
                    &:nth-child(5) {
                        min-width: 120px;
                    }
                    &:nth-child(6) {
                        min-width: 80px;
                    }
                    &:nth-child(7) {
                        min-width: 100px;
                    }
                    &:nth-child(8) {
                        min-width: 140px;
                        @media (max-width: 1445px) {
                            display: none;
                        }
                    }
                    &:nth-child(9) {
                        min-width: 105px;
                    }
                }
                &:not(:last-of-type) {
                    border-bottom: 1px dashed var(--light-border);
                }
                &:hover {
                    background-color: var(--light-shade-primary);
                }
            }
        }
    }
}
/**=====================
  3.46 Add-product CSS Start
==========================**/