/**=====================
    3.31 Order-history CSS start
==========================**/
.prooduct-details-box {
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 16px;
    cursor: pointer;
  }

  .btn {
    line-height: 1.2;

    @media (max-width:575px) {
      padding: $btn-xs-padding;
      font-size: $btn-xs-font-size;
    }
  }

  >.d-flex {
    border: 1px solid $light-background;
    border-radius: 10px;
    padding: 15px 15px 15px 0;
    width: 100%;
    position: relative;
  }

  .rating {
    color: #ffaf45;
    letter-spacing: 3px;
  }

  .product-name {
    a {
      color: gray;
    }

    h6 {
      margin-bottom: 0;
      font-weight: 600;
    }
  }

  .btn {
    position: absolute;
    right: 20px;
    bottom: 15px;
  }

}

.order-history {
  .input-group {
    text-align: right;
    max-width: 300px;
  }

  table {

    th,
    td {
      text-align: center;
    }

    thead {
      tr {
        th {
          vertical-align: middle;
          border-top: none;
          background-color: $body-color;
          border: 1px solid $light-gray;
          padding: 1.25rem;
          font-size: 16px;
        }
      }
    }

    .qty-box {
      width: 120px;
      margin: 0 auto;
      border-radius: 5px;
      overflow: hidden;

      input {
        width: 44px;
        max-width: 44px;
        background-color: transparent;
        border: none !important;
      }

      .input-group {
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid $light-gray;
        overflow: hidden;
      }

      .btn {
        background-color: transparent;
        border: none;

        &.bootstrap-touchspin-down {
          border-right: 1px solid;

          [dir="rtl"] & {
            border-right: unset;
            border-left: 1px solid;
          }
        }

        &.bootstrap-touchspin-up {
          border-left: 1px solid;

          [dir="rtl"] & {
            border-left: unset;
            border-right: 1px solid;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .order-history {
    table {
      width: 900px;
      overflow: auto;
    }
  }
}
.order-history{
  .rdt_Table{
    border: 1px solid rgb(239, 239, 239);
    .rdt_TableHead{
        .rdt_TableHeadRow{
            .rdt_TableCol{
                border: none !important;
                background-color: rgb(248, 248, 248);
                padding: 0.75rem;
                cursor: pointer;
                .rdt_TableCol_Sortable{
                    div{
                        color: #2F2F3B;
                        font-weight: 700;
                        font-size: 16px;
                    }
                }
                &:last-child{
                  min-width: 20px;
                  max-width: 50px;
                }
            }
            
        }
    }
    .rdt_TableBody{
        .rdt_TableRow{
          outline: none;
            .rdt_TableCell{
                padding: 0.75rem;
                cursor: pointer;
                color: rgb(47, 47, 59);
                font-size: 14px;
                font-weight: 400;
                .product-name {
                  a{
                    font-size: 15px;
                    color: #898989;
                  }
                  .order-process {
                    font-size: 13px;
                    padding-left: 15px;
                    .order-process-circle {
                      position: relative;
                      &:before {
                        position: absolute;
                        content: "";
                        width: 5px;
                        height: 5px;
                        border-radius: 100%;
                        background-color: #898989;
                        left: -15px;
                        top: -6px;
                      }
                    }
                  }
                }
                &:last-child{
                  min-width: 20px;
                  max-width: 50px;
                }
            }
            &:not(:last-of-type){
              border: none !important;
            }
           
        }
    }
}
}
/**=====================
    3.31 Order-history CSS End
==========================**/