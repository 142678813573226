.package-title {
    display: inline-block;
    width: 250px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.features-faq.card .card-body .package-detail {
    max-height: 200px;
    /* word-break: break-all; */
    min-height: 200px;
}

.full-text p {
    -webkit-line-clamp: none !important;
    display: block !important;
    -webkit-box-orient: inherit !important;
}
.timeline-event-wrapper {
    top: -30px !important;
}

.position-bottom {
    position: absolute;
    bottom: 0;
}