/**=====================
  4.3 Update CSS Start
==========================**/
@import "../utils/variables";
// jsgrid css
.jsgrid-filter-row {
    select,
    input {
        padding: 6px 10px;
        border: 1px solid $horizontal-border-color;
        background: $white;
    }
}
// tooltip css start
.tooltip {
    &.bs-tooltip-top {
        .tooltip-arrow {
            &:before {
                border-top-color: $light-primary;
            }
        }
    }
    &.bs-tooltip-bottom {
        .tooltip-arrow {
            &:before {
                border-bottom-color: $light-primary;
                border-top-color: transparent;
            }
        }
    }
    &.bs-tooltip-start {
        .tooltip-arrow {
            &:before {
                border-left-color: $light-primary;
                border-top-color: transparent;
            }
        }
    }
    &.bs-tooltip-end {
        .tooltip-arrow {
            &:before {
                border-right-color: $light-primary;
                border-top-color: transparent;
            }
        }
    }
    .tooltip-inner {
        background-color: $light-primary;
        color: $primary-color;
    }
    .tooltip-arrow {
        &:before {
            border-top-color: $light-primary;
        }
    }
}
// tooltip css end
// dropdown css start
.dropdown-basic {
    .dropdown {
        .dropdown-content {
            a {
                padding: 6px 16px;
                color: $dark-editor-document;
                opacity: 0.6;
                font-size: 13px;
                border-top: 1px solid $light-semi-gray;
                background: $white;
                &:hover {
                    background-color: $white;
                }
            }
            .dropdown-header {
                padding: 8px 16px;
                font-weight: 400;
                color: $dark-color;
                font-size: 13px;
            }
        }
    }
    .dropup {
        .dropup-content {
            top: auto;
        }
    }
}
// dropdown css end
// accordian css start
.default-according {
    .card {
        .card-header {
            padding: 0;
            border: none;
            border-radius: 0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            &.bg-primary,
            &.bg-secondary {
                .btn {
                    border-color: $transparent-color;
                }
            }
            i {
                position: initial;
                font-size: 20px;
                display: inline-block;
                vertical-align: text-bottom;
                margin-right: 5px;
                [dir="rtl"] & {
                    margin-right: unset;
                    margin-left: 5px;
                }
            }
            h5 {
                margin-top: 0;
            }
            .btn-link {
                padding: 12px 18px;
                gap: 8px;
                letter-spacing: 0.7px;
                font-family: Inter;
                border: 1px solid $light-semi-gray;
                border-radius: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                @media (max-width: 575px) {
                    padding: 12px 15px;
                }
            }
        }
        .card-body {
            padding: 15px 20px;
            line-height: 1.8;
            font-size: 13px;
            border-color: $light-semi-gray;
            border-radius: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            color: $dark-color;
            .common-flex {
                li {
                    img {
                        height: auto;
                    }
                }
            }
        }
    }
}
// accordian css end
// tab-bootstrap css start
.nav-tabs {
    border-bottom-color: $light-semi-gray;
    .nav-bottom {
        .nav-item {
            .nav-link.active {
                border-color: $light-semi-gray;
            }
        }
    }
}
.nav-tabs {
    .nav-item {
        &.show {
            .nav-link {
                font-weight: 500;
            }
        }
    }
    .nav-link {
        color: $dark-color;
        &.active {
            font-weight: 500;
        }
    }
    &.nav-bottom {
        .nav-item {
            .nav-link {
                &.active {
                    border-color: $light-gray $light-gray #fff;
                }
            }
        }
    }
}
.dropdown-menu {
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
    z-index: 2;
    .dropdown-item {
        color: #2b2b2b;
        opacity: 0.9;
        font-size: 13px;
        padding: 6px 12px;
        border-top: 1px solid $light-semi-gray;
        background: var(--white);
        &:hover {
            background-color: var(--white);
        }
    }
    .dropdown-divider {
        margin: 0;
        border-top: 1px solid $gray-60;
    }
}
// tab-bootstrap css end
.border-tab {
    .nav-tabs {
        .nav-item {
            .nav-link {
                &.active {
                    border-bottom: 2px solid $primary-color;
                }
                &.show {
                    border-bottom: 2px solid $primary-color;
                }
                &:focus {
                    border-bottom: 2px solid $primary-color;
                }
            }
        }
    }
}
.timeliny {
    .timeliny-dot::before {
        font-size: $btn-lg-font-size;
    }
}
.component {
    .input-group {
        .btn {
            line-height: 32px;
            text-transform: capitalize;
        }
    }
}
.login-card {
    .login-main {
        .theme-form {
            label {
                font-size: $body-font-size;
            }
            .show-hide {
                top: 50%;
            }
        }
    }
}
// faq css end
.job-filter {
    .faq-form {
        .form-control {
            font-size: 14px;
        }
        .search-icon {
            width: 16px;
            height: 16px;
        }
    }
}
// editor css
.note-btn-group {
    .dropdown-toggle {
        &.show {
            ~.dropdown-menu {
                display: block;
                top: 30px;
            }
        }
    }
}
// owl-carousel css
.carousel {
    .carousel-indicators {
        margin-bottom: 3px;
    }
}
.mouse-wheel {
    .owl-stage-outer {
        .owl-stage {
            width: 5600px !important;
            .owl-item {
                width: 225.333px !important;
            }
        }
    }
}
.carousel-item {
    .carousel-caption {
        width: 100%;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 26px 10px;
        background-color: rgba($dark-card-background, 0.51);
    }
    .carousel-opacity {
        background-color: rgba($light-color, 0.51);
    }
}
.carousel {
    .carousel-control-prev-icon {
        width: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
        height: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    }
    .carousel-control-next-icon {
        width: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
        height: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    }
}
// Rating 
.rating-header {
    h4 {
        [dir="rtl"] & {
            direction: ltr;
        }
    }
}
// Animated modal
.animate-img {
    .animate-widget {
        img {
            width: 100%;
            height: 480px;
            margin: 0 auto;
            object-fit: cover;
            @media (max-width:575px) {
                height: 300px;
            }
        }
    }
}
.modal-popup {
    &.modal {
        top: 20%;
        left: 50%;
        transform: translate(-50%, 0);
        .modal-dialog {
            .theme-close {
                width: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
                height: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
}
.animated-modal {
    .form-select {
        @media (max-width:442px) {
            width: 100% !important;
        }
        &:focus {
            box-shadow: unset;
            border-color: unset;
        }
    }
}
// TO-DO scss
.task-container {
    .badge {
        line-height: 13px;
    }
}
// Add-post scss
.add-post {
    form {
        .form-control {
            &:focus {
                border: 1px solid var(--recent-dashed-border);
                box-shadow: unset;
            }
        }
    }
}
// Modal page
.modal {
    .modal-body {
        .card {
            box-shadow: none !important;
        }
    }
}
.modal-dialog {
    .modal-content {
        .modal-body {
            p {
                a {
                    margin-right: 0;
                }
            }
        }
    }
}
.alert-theme {
    button {
        top: 45px !important;
        right: 20px !important;
        color: var(--theme-default);
        [dir="rtl"] & {
            right: unset !important;
            left: 20px !important;
        }
    }
    span {
        +span {
            +span {
                background-color: $white;
                color: var(--theme-default);
                border-radius: 4px;
                box-shadow: 0 2px 14px -3px rgba($black, 0.2);
            }
        }
    }
    .close {
        color: var(--theme-default);
    }
    i {
        padding: 20px;
        padding-right: 0 !important;
        margin-right: 20px !important;
    }
    .progress {
        margin-top: -2px !important;
        height: 2px;
        width: 100%;
        .progress-bar {
            background-color: var(--theme-default);
        }
    }
}
.ace-editor {
    height: 400px;
    width: 100%;
}
.editor-toolbar {
    width: 100% !important;
    &.fullscreen {
        z-index: 100;
    }
}
.CodeMirror {
    top: 0 !important;
}
.CodeMirror-fullscreen {
    top: 50px !important;
}
// rangeslider
.irs-from,
.irs-to,
.irs-single {
    background: var(--theme-default);
    &:after {
        border-top-color: var(--theme-default);
    }
}
.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge {
    background-color: var(--theme-default);
}
// sweetalert css
.swal-button {
    background-color: var(--theme-default);
    &:active {
        background-color: darken($primary-color, 5%);
    }
}
.swal-button--cancel {
    background-color: #EFEFEE !important;
}
.clipboaard-container {
    h6 {
        line-height: 1.6;
        font-size: $paragraph-font-size;
    }
}
fieldset {
    .bootstrap-touchspin-postfix,
    .bootstrap-touchspin-prefix {
        border-radius: 0;
        padding: .4rem .75rem;
    }
    .input-group-lg {
        .touchspin {
            height: 50px;
        }
    }
}
.f1 .f1-steps .f1-step .f1-step-icon {
    padding: 12px;
}
.form-inline .form-group {
    display: flex;
}
.input-group-text {
    i {
        line-height: 1.5;
    }
}
// animated modal css
.animated-modal {
    .form-select {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
}
.card-wrapper {
    padding: 14px;
    dl {
        dt {
            font-weight: 500;
        }
        dd {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.starter-main {
    .alert-primary {
        &.inverse {
            padding: 13px 20px 13px 65px;
            border-color: $primary-color;
            color: $dark-color;
            [dir="rtl"] & {
                padding: 13px 65px 13px 20px;
            }
            &:before {
                content: "";
                position: absolute;
                left: 46px;
                width: 15px;
                height: 15px;
                top: 50%;
                background-color: var(--theme-default);
                transform: translateY(-50%) rotate(45deg);
                [dir="rtl"] & {
                    left: unset;
                    right: 46px;
                }
            }
            i {
                padding: 17px 20px;
                display: flex;
                align-items: center;
                background-color: $primary-color;
                color: $white;
                border-radius: 3px 0 0 3px;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                [dir="rtl"] & {
                    right: 0;
                    left: unset;
                }
            }
        }
    }
    .alert {
        background-color: rgba(0, 157, 181, 0.4);
    }
    .card-body {
        ul {
            margin-bottom: 14px;
        }
        dl {
            dd {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
// owl carousel css
.owl-theme {
    .owl-dots {
        button {
            span {
                @media (max-width:575px) {
                    margin: 5px !important;
                }
            }
        }
        .owl-dot {
            span {
                background: lighten($primary-color, 15%);
            }
            &.active,
            &:hover {
                span {
                    background: var(--theme-default);
                }
            }
        }
    }
    .owl-nav {
        &.disabled {
            &+.owl-dots {
                margin-bottom: -10px;
                margin-top: 25px;
            }
        }
    }
}
// input-mask
.input-mask {
    .card-wrapper {
        .form-control {
            font-size: 14px;
        }
    }
}
// Todo
.todo {
    .todo-list-container {
        .mark-all-tasks {
            top: 12px !important;
        }
    }
}
.todo {
    .todo-list-wrapper {
        .mark-all-tasks {
            .mark-all-btn#mark-all-incomplete {
                .action-box {
                    border-color: $success-color !important;
                }
            }
            .mark-all-btn#mark-all-incomplete {
                color: $success-color !important;
            }
            .mark-all-btn#mark-all-finished {
                color: $danger-color !important;
            }
        }
        .task {
            .task-container {
                .task-label {
                    font-size: 16px !important;
                    color: var(--body-light-font-color) !important;
                }
            }
        }
    }
    .action-box {
        &.completed {
            background-color: $danger-color !important;
            border-color: $danger-color !important;
        }
    }
    .todo-list-footer {
        .new-task-wrapper {
            &.visible {
                margin-bottom: 16px;
            }
        }
    }
}
// Dragable-card
.ui-sortable {
    .list-group {
        .list-group-item {
            &.active {
                z-index: 0 !important;
            }
        }
    }
}
.list-group {
    i {
        margin-right: 0 !important;
    }
    img {
        margin-right: 0 !important;
    }
}
$badge-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$badge-color: $primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $badge-name {
    $i: index($badge-name, $var);
    .badge {
        &.bg-light-#{$var} {
            color: nth($badge-color, $i);
        }
    }
}
@keyframes swing {
    20% {
        transform: rotate(15deg);
    }
    40% {
        transform: rotate(-10deg);
    }
    60% {
        transform: rotate(5deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.Typeahead {
    .Typeahead-menu {
        &.is-open {
            .header-search-suggestion {
                .ProfileCard-details {
                    .ProfileCard-realName {
                        a {
                            color: #2f2f3b;
                        }
                        svg {
                            display: inline-block;
                            width: 16px;
                            stroke: #2f2f3b;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }
}
.react-datepicker-popper[data-placement^=bottom] {
    .react-datepicker__triangle {
        &:after {
            left: -15px;
        }
        &:before {
            left: -15px;
        }
    }
}
.flatpicker-calender {
    >div {
        width: 100%;
    }
}
.rmdp-container {
    width: 100%;
}
.react-tagsinput-tag {
    background-color: #E5E5E5;
    border: 1px solid #E5E5E5;
    color: black;
    .react-tagsinput-remove {
        color: black;
    }
}
.pagination {
    .page-item {
        &.active {
            .page-link {
                background-color: var(--theme-default);
                color: var(--white);
                border-color: var(--theme-default);
            }
        }
        .page-link {
            color: var(--theme-default);
            &:focus {
                box-shadow: none;
            }
        }
    }
}
.btn {
    &:first-child {
        &:active {
            border-color: var(--theme-default);
        }
    }
}
.avatar-showcase {
    .pepole-knows {
        ul {
            li {
                .add-friend {
                    img {
                        height: auto;
                    }
                }
            }
        }
    }
}
.social-app-profile {
    .hovercard {
        .info {
            ul {
                li {
                    .active {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
.css-t3ipsp-control {
    box-shadow: none !important;
    border-color: #dee2e6 !important;
    &:hover {
        border-color: #dee2e6 !important;
    }
}
.height-equal.card {
    height: calc(100% - 30px);
}
.toast-header {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}
.dzu-dropzone {
    min-height: 250px;
    text-align: center;
    overflow: hidden;
    border: 0;
    .dz-message {
        text-align: center;
        .icon-cloud-up {
            font-size: 50px;
            color: rgb(33, 37, 41);
            font-weight: 400;
        }
        h6 {
            font-weight: 600;
            color: rgb(33, 37, 41);
            font-size: 14px;
        }
        .note {
            font-size: 14px;
            color: rgb(33, 37, 41);
            font-weight: 400;
        }
    }
}
.dropzone-secondary {
    .dzu-dropzone {
        border: 2px dashed #F94C8E;
        border-radius: 15px;
        .icon-cloud-up {
            color: #F94C8E;
        }
    }
}
.custom-padding-slider {
    padding-bottom: 35px !important;
    .slick-arrow {
        display: none !important;
    }
    .slick-dots {
        margin-top: 25px;
        li {
            button {
                &::before {
                    font-size: 10px;
                    width: 20px;
                    height: 20px;
                    color: var(--theme-default);
                }
            }
        }
    }
}
.buttons-box {
    &.btn-group-vertical {
        .dropdown {
            width: 100%;
            .dropdown-toggle {
                text-align: center;
                width: 100%;
                border-radius: 0;
                color: var(--bs-btn-color) !important;
            }
            &.dropup {
                .dropdown-toggle {
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }
        }
    }
}
.CodeMirror-scroll {
    min-height: 120px !important;
}
.add-primary-post {
    border: 2px dashed var(--theme-default);
    background-color: #D0E8EB;
    min-height: 150px;
    border-radius: 15px;
    .icon-cloud-up {
        color: var(--theme-default) !important;
    }
    .dzu-dropzone {
        min-height: 190px !important;
        border-radius: 15px;
    }
}
.btn-showcase .btn {
    margin: 0 10px 10px 0;
}
.editor-toolbar {
    border-bottom: none;
    width: 100% !important;
}
.quill {
    border-radius: 5px;
    border: 1px solid var(--recent-border);
}
.ql-toolbar {
    &.ql-snow {
        border: 0;
        width: 100% !important;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}
.ql-container {
    &.ql-snow {
        border: 0;
        top: 0 !important;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
.CodeMirror {
    border-top: none !important;
    top: 0 !important;
}
.md-sidebar {
    .md-sidebar-aside {
        .default-according {
            .collapse {
                .location-checkbox,
                .checkbox-animated {
                    margin-top: 18px;
                    margin-bottom: 18px;
                }
                .form-check-input {
                    &:checked {
                        background-color: transparent;
                        border-color: transparent;
                    }
                }
            }
        }
    }
}
.leaflet-pane {
    z-index: 9;
}
.ql-bubble.ql-toolbar {
    button {
        svg {
            stroke: $dark-all-font-color;
        }
    }
}
.ql-bubble {
    .ql-toolbar {
        button {
            svg {
                stroke: $dark-all-font-color;
                .ql-stroke {
                    stroke: $dark-all-font-color;
                }
            }
        }
    }
}
.page-wrapper{
    &.horizontal-wrapper{
        .page-header{
            .header-left-wrapper{
                &.col-auto{
                    padding-left: 25px !important;
                }
            }
        }
    }
}
.tree{
    .checkbox-icon{
            fill: var(--theme-default) !important;
    }
}
.range-slider{
    .range-track{
        .range-track-bar{
            background: linear-gradient(to right, rgb(0, 157, 181) 0%, rgb(0, 157, 181) 50%, rgb(204, 204, 204) 50%, rgb(204, 204, 204) 100%) !important;
        }
    }
}
.ace-monokai {
    .ace_print-margin {
        background: transparent;
    }
}
.page-wrapper.horizontal-wrapper {
	.simplebar-scrollable-x {
		height: 50px !important;
		width: 100% !important;
	}
}
.page-wrapper.horizontal-wrapper {
	[data-simplebar] {
		position: relative;
	}
}
.todo {
    .todo-list-wrapper {
        .mark-all-tasks {
            .btn-label {
                &.txt-success {
                    ~.action-box {
                        &.completed {
                            background-color: #83BF6E !important;
                            border-color:  #83BF6E !important;
                        }
                    }
                }
            }
        }
    }
}
.action-box{
    &.completed{
        .icon-check{
            background-color: #83BF6E !important;
        }
    }
}
.msger-inputarea {
    .dropdown {
        .chat-icon {
            background-color: transparent !important;
            border: none;
            box-shadow: none;
            transform: translateY(-75px) !important;
            button {
                border: none;
                border-radius: 50px;
                width: auto;
                background: $white;
                opacity: 0.9;
                padding: 2px 3px 1px 3px;
                font-size: 13px;
                svg {
                    border-radius: 40%;
                    height: 45px;
                    width: 45px;
                }
                &.camera-icon {
                    svg {
                        fill: $font-gray-color;
                    }
                }
                &.attchment-icon {
                    svg {
                        fill: $white;
                        stroke: $font-gray-color;
                    }
                }
            }
        }
    }
}
/**=====================
    4.3 Update CSS Ends
==========================**/