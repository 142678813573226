@import "bootstrap/dist/css/bootstrap.min.css";
@import "react-toastify/dist/ReactToastify.css";

@import "./icoicon/icons";
@import "./themify.scss";
@import "./style.scss";
@import "./responsive.scss";
@import "./react-plugin.scss";

//Filepond
@import "filepond/dist/filepond.min.css";
@import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

//Dropzone
@import "react-dropzone-uploader/dist/styles.css";

@import "./whether-icon/whether-icon";

//leaflet map
@import 'leaflet/dist/leaflet.css';

//quill editor
@import 'react-quill/dist/quill.snow.css';
@import 'react-quill/dist/quill.bubble.css';

//simple mde editor
@import "easymde/dist/easymde.min.css";

//Gallery
@import 'photoswipe/dist/photoswipe.css';

//calendar
@import 'react-calendar/dist/Calendar.css';

// react date-piker
@import "react-datepicker/dist/react-datepicker.css";

//font awesome
@import 'font-awesome/scss/font-awesome.scss';

//swiper
@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'swiper/css/navigation';
@import "swiper/css/grid";

//Typeahead
@import "~react-bootstrap-typeahead/css/Typeahead.css";

//React-Slick
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

// timeline
@import 'react-vertical-timeline-component/style.min.css';

//flag-icons
@import "/node_modules/flag-icons/css/flag-icons.min.css";

// image cropper css
@import "react-image-crop/src/ReactCrop.scss";

//simple bar
@import "simplebar/dist/simplebar.css";

//tag inputs
@import "react-tagsinput/react-tagsinput.css";

