.App {
    text-align: center;
}

.invalid-feedback {
    display: block;
}

.theme-form input[name=hour],.theme-form input[name=minute] {
    padding: 0px !important;
}

.required-field::after {
    content: "*";
    color: red;
    margin-left:2px
}

.form-control::placeholder {
    /* font-weight: bold; */
    opacity: 0.5;
}