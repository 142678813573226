/**=====================
   3.1 Blog CSS start
==========================**/
.blog-box {
  img {
    border-radius: 0.25rem;
  }
  &.blog-details {
    .blog-social {
      li {
        &:last-child {
          @media (max-width:370px) {
            padding-left: 0;
          }
        }
      }
    }
  }
  .top-radius-blog {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  h6 {
    font-weight: 600;
    margin: 15px 0;
  }
  .blog-details {
    padding: 20px;
    .blog-social {
      li {
        display: inline-block;
        font-size: 12px;
        line-height: 1;
        color: $theme-body-sub-title-color;
        +li {
          padding-left: 20px;
          line-height: 1;
          padding-right: 0;
        }
        i {
          padding-right: 5px;
          font-weight: 300;
        }
        &:nth-child(n+2){
          border-left: 1px solid var(--light-border);
          margin-left: 20px;
          padding-left: 20px;
          border-radius: 0;
        }
      }
    }
  }
  .blog-details-main {
    hr {
      background-color: $gray-60;
    }
    .blog-social {
      margin: 20px 0;
      li {
        display: inline-block;
        font-size: 13px;
        color: $theme-body-sub-title-color;
        padding: 0 24px;
        border-right: 1px dotted;
        border-radius: 0;
        @media (max-width: 1676px) {
          padding: 0 10px;
        }
        &:last-child {
          border-right: 0;
        }
        i {
          padding-right: 5px;
        }
      }
    }
    .blog-bottom-details {
      margin: 20px;
      font-size: 14px;
      line-height: 1.714;
      font-weight: 500;
    }
  }
  .blog-date {
    color: $theme-body-sub-title-color;
    span {
      font-size: 36px;
      font-weight: 500;
      padding-right: 5px;
    }
  }
}
.blog-box.blog-shadow {
  &:before {
    box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;
    border-radius: 5px;
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    bottom: 0;
  }
  &:hover {
    &:before {
      box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.65);
      transition: all 0.3s ease;
    }
  }
  .blog-details {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    h4 {
      color: var(--white);
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      color: var(--white);
      margin-bottom: 10px;
    }
    .blog-social {
      li {
        color: var(--white);
      }
    }
  }
}
.blog-list {
  .blog-details {
    .blog-social {
      margin-top: 0;
    }
    h6 {
      margin-top: 0;
      color: gray;
      font-weight: 500;
    }
    hr {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  img {
    padding: 17px;
  }
}
.blog-grid {
  img {
    width: 100%;
  }
  .blog-details {
    padding: 20px;
    h6 {
      font-size: 14px;
      margin-bottom: 0;
      line-height: 1.8;
    }
  }
}
.blog-box {
  position: relative;
  max-height: 100%;
  align-items: center;
  .blog-details {
    h6 {
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    p {
      line-height: 1.8;
      letter-spacing: 0.7px;
      margin-top: 20px;
      margin-bottom: 0;
      color: $theme-body-sub-title-color;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      @media (max-width: 1570px) {
        -webkit-line-clamp: 2;
      }
    }
    h4 {
      font-size: 17px;
      font-weight: 500;
      line-height: 1.5;
      margin-top: 20px;
      margin-bottom: 0;
    }
    .single-blog-content-top {
      margin-top: 20px;
      border-top: 1px solid $light-semi-gray;
    }
    .blog-social,
    .comment-social {
      li {
        font-size: 15px;
      }
    }
  }
}
.comment-box {
  padding-block: 20px;
  hr {
    margin-top: 18px;
    margin-bottom: 30px;
  }
  li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    ul {
      margin-left: 135px;
    }
  }
  p {
    line-height: 1.6;
    color: $theme-body-sub-title-color;
    text-align: justify;
    margin-bottom: 0;
  }
  h4 {
    font-weight: 600;
  }
  .d-flex {
    img {
      margin-right: 20px !important;
      border-radius: 10px;
      height: 90px;
      padding: 7px;
      width: 90px;
      border: 1px solid $light-semi-gray;
    }
    h4 {
      font-weight: 500;
      margin-bottom: 5px;
      span {
        padding-left: 10px;
        font-size: 13px;
        color: $theme-body-sub-title-color;
        font-weight: 400;
      }
    }
  }
  .comment-social {
    li {
      display: inline-block;
      color: $light-paragraph;
      padding-left: 20px;
      margin-bottom: 0;
      text-transform: uppercase;
      cursor: pointer;
      border-radius: 0;
      &:first-child {
        border-right: 1px solid var(--light-border);
        padding-right: 20px;
      }
      i {
        padding-right: 10px;
      }
    }
  }
}
.add-post {
  .dropzone {
    &.dz-clickable {
      border: 2px dashed var(--theme-default);
      background-color: $light-primary;
      i {
        color: var(--theme-default);
      }
    }
  }
  form{
    .form-label{
      margin-bottom: 10px;
    }
    .m-checkbox-inline{
      line-height: 1;
    }
  }
}

.blog-box {
	.blog-details-main {
		.blog-social {
			align-items: center;
			display: flex;
			justify-content: center;
		}
	}
}
.blog-single {
	.blog-box {
		.blog-details {
      @media (max-width: 1199px){
        padding: 15px;
      }

		}
	}
}
.blog-box {
	.blog-details {
		.blog-social {
      display: block;
			li {
        @media (max-width: 767px){
          font-size: 13px;
        }
        @media only screen and (max-width: 575px){
          font-size: 12px;
          font-weight: 500;
        }
        &:nth-child(n+2) {
          @media (max-width: 767px){
            margin-left: 10px;
            padding-left: 10px;
          }
          @media only screen and (max-width: 575px){
            border-left: none;
            margin-left: 10px;
            padding-left: 0;
          }
					
				}
        &:last-child {
          @media only screen and (max-width: 360px){
            padding-left: 0;
          }
				
				}
			}
		}
	}
}

/**=====================
   3.1 Blog CSS ends
==========================**/